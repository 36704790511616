.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.form{
    display: flex;
    background: #9d9d9d;
    background: -webkit-linear-gradient(left, #9d9d9d 0%, #ffffff 29%, #ffffff 50%, #ffffff 71%, #9d9d9d 100%);
    background: linear-gradient(to right, #9d9d9d 0%, #ffffff 15%, #ffffff 50%, #ffffff 85%, #9d9d9d 100%);
    flex-direction: column;
    align-items: end;
    border: 10px solid rgba(36, 46, 77, 0.5);
    border-radius: 20px;
    box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.5);
    transition: 1s ease;
}
.formWH {
    width: 90vw;
    height: 90vh;
    background: white;
    color:#ffbf00;
    text-shadow: 0px 0px 3px black;
}

.form:hover {
    box-shadow: none;
    transition: 1s ease;
}

.frameWaterClean {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.formAdmin {
    margin-top: 20px;
    width: 99%;
}

.headerForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.titleForm {
    width: 90%;
    padding: 0 1vw;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}