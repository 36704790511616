.wrapper {
    min-height: 100vh;
    border: 1px solid #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader span {
    display: block;
    height: 15px;
    width: 15px;
    background: #19e3d5;
    margin: 0 7px;
    border-radius: 10px;
    animation: 0.7s upDown infinite;
}

.preloader span:first-child {
    animation-delay: .1s;
}

.preloader span:nth-child(2) {
    animation-delay: .2s;
}

.preloader span:nth-child(3) {
    animation-delay: .3s;
}

.preloader span:nth-child(4) {
    animation-delay: .4s;
}

.preloader span:last-child {
    animation-delay: .5s;
}

@keyframes upDown {
    0% {
        height: 15px;
        background: #19e3d5;
    }
    50% {
        height: 60px;
        background: #d33deb;
    }
    100% {
        height: 15px;
        background: #f00e43;
    }
}