.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.preloader {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader span {
    display: block;
    height: 5px;
    width: 5px;
    background: #19e3d5;
    margin: 0 3px;
    border-radius: 10px;
    animation: 1.0s upDown infinite;
}

.preloader span:first-child {
    animation-delay: .1s;
}

.preloader span:nth-child(2) {
    animation-delay: .2s;
}

.preloader span:nth-child(3) {
    animation-delay: .3s;
}

.preloader span:nth-child(4) {
    animation-delay: .4s;
}

.preloader span:last-child {
    animation-delay: .5s;
}

@keyframes upDown {
    0% {
        height: 5px;
        background:cyan;
    }
    50% {
        height: 30px;
        background: gold;
    }
    100% {
        height: 5px;
        background: red;
    }
}