.wrapper {
    display: flex;
    min-height:60vh;
}

.menu {
    text-align: center;
}

.menu > div:hover {
    color: white;
    cursor: pointer;
}

.contentAdmin {
    border-left: 1px solid #000;
}

.menu > div:last-child {
    padding-bottom: 10px;
}
