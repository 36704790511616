.menu{
    margin-top: 20px;
    padding: 20px 20px;
    background: rgba(255, 255, 255, 0.2);
    height: 100vh;
    border-right: 15px solid #ffbf00;
    position: fixed;
    transition: 1s ease;
    display: flex;
    flex-flow: column wrap;
    overflow:auto;
    align-content: flex-start;
}
.wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.menu:hover{
    transition: 1s ease;
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn > button {
    margin-top: 10px;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 25px;
}