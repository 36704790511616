* {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

html, body {
    height: 100%;
}

body {
    background: -webkit-linear-gradient(315deg, rgba(36, 46, 77, 0.9), rgba(137, 126, 121, 0.9));
    background: linear-gradient(135deg, rgba(36, 46, 77, 0.9), rgba(137, 126, 121, 0.9));
    font-family: Open Sans, Arial, sans-serif;
    font-size: 1.3em;
}
